import React, { useCallback, useEffect } from "react";
import ListItem from "../ListItem";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";

import ReactImageGallery from "react-image-gallery";
import { isImageOrVideo } from "../../../data/utils";

export default function Items({ imageArray }) {
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const active = 0;
  const handleCloseModal = () => {
    setModal(false);
  };

  const openModal = (item, type) => {
    if (type === "video") return;
    const i = images.map((v) => v.original).indexOf(item?.s3URL);
    setImage(i);
    setModal(true);
  };

  useEffect(() => {
    if (imageArray) {
      let arr = imageArray.map((v) => {
        return { original: v?.s3URL, thumbnail: v?.thumbnail, type: isImageOrVideo(v?.s3URL) };
      });
      setImages(arr);
    }
  }, [imageArray]);

  return (
    <>
      {imageArray && imageArray.length > 0
        ? imageArray.map((item, index) => {
            const type = isImageOrVideo(item?.s3URL);
            if (type === "video") {
              return (
                <div
                  onClick={() => openModal(item, type)}
                  class={`h-100 carousel-item${
                    index == active ? " active" : ""
                  } `}
                >
                  <video
                    controls
                    // muted
                    className="rounded-2"
                    height={400}
                    style={{
                      objectFit: "contain",
                      marginBottom: "-7px",
                      width: "100%",
                    }}
                  >
                    <source src={item?.s3URL} />
                  </video>
                </div>
              );
            } else if (type === "image") {
              return (
                <div
                  onClick={() => openModal(item, type)}
                  class={`h-100 carousel-item${
                    index == active ? " active" : ""
                  } `}
                >
                  <img
                    class={`w-100`}
                    height={400}
                    style={{ objectFit: "contain" }}
                    src={item ? item?.s3URL : "../assets/images/notFound.png"}
                    onError={(err) => console.log(err.target.message)}
                    onErrorCapture={(e) => console.log(e, "capture")}
                    // onClick={() => openImageViewer(index)}
                    alt="bg"
                  />
                  {/* <ul class="butm">
                {isImageOrVideo(item) !== "video" && (
                  <ListItem
                    innerhtmlType={"html"}
                    value={
                      <a
                        onClick={() => window.print()}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="../assets/images/Print.png"
                          class="Print"
                          style={{ width: "unset" }}
                        />{" "}
                        {t("Print")}
                      </a>
                    }
                  />
                )}
                <ListItem
                  innerhtmlType={"html"}
                  value={
                    <a href={item} download style={{ cursor: "pointer" }}>
                      <img
                        src="../assets/images/Download.png"
                        class="Print"
                        style={{ width: "unset" }}
                      />{" "}
                      {t("Download")}
                    </a>
                  }
                />
              </ul> */}
                </div>
              );
            }
          })
        : ""}
      <Modal
        size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        <ModalHeader
          style={{ alignSelf: "end" }}
          toggle={handleCloseModal}
          className="d-flex justify-content-end border-0"
        ></ModalHeader>
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", padding: "0xp" }}
        >
          {/* <div
          className="bg-light p-2 rounded-2"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 999,
            }}
          >
            <img
              src="/assets/images/Close.png"
              height={25}
              onClick={() => handleCloseModal()}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div> */}
          <div className="my-image-gallery">
            <ReactImageGallery
              startIndex={image}
              items={images || []}
              renderItem={(e) => {
                const item = e?.original;
                switch (e.type) {
                  case "image":
                    return (
                      <img
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100vh",
                        }}
                        src={item ? item : "../assets/images/notFound.png"}
                        // onError={() => filterImageArray(item)}
                        alt="bg"
                      />
                    );
                  case "video":
                    return (
                      <video
                        controls
                        className="rounded-2"
                        // height={400}
                        style={{
                          objectFit: "contain",
                          width: "-webkit-fill-available",
                          height: "100vh",
                        }}
                      >
                        <source src={item} />
                      </video>
                    );
                }
              }}
              showThumbnails={false}
              showBullets={false}
              showFullscreenButton={false}
              showPlayButton={false}
              isRTL={false}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
