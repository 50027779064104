import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HeadingWithDatePicker from "../../Tools/DatepickerWithHeading";
import BoxItem from "../../Tools/BoxList";
import TradeBox from "../../Tools/TradeBox";
import { overviewFun } from "../../../Reducer/dashboard/dashboardSlice";
import Skeletons1 from "../../Tools/Skeletons/Skeletons1";
import { Icon } from "@iconify/react/dist/iconify.js";

const Overview = ({ data, loading, syncing }) => {
  const overviewData = useSelector((state) => state.dashboard?.overview);
  const error = useSelector((state) => state.dashboard.error);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(overviewFun({ time: "This weeks" }));
  }, []);
  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append("time", formData);
    dispatch(overviewFun(formData));
  };

  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  }

  return (
    <>
      <TradeBox
        parentStyle={"set1"}
        childStyle={"trade"}
        heading={
          <div className="d-flex justify-content-between">
            <span>Overview</span>
            {syncing && (
              <span
                className="d-flex align-items-center gap-1"
                style={{ fontSize: "14px" }}
              >
                <Icon
                  icon="eos-icons:loading"
                  style={{ color: "#4c7ee8", fontSize: "14px" }}
                />
                <span>Syncing data... </span>
              </span>
            )}
          </div>
        }
        // subHeading={"Primary Overview"}
        onClick={onsubmit}
        component={
          loading ? (
            <Skeletons1 lines={2} />
          ) : (
            <>
              <BoxItem
                parentStyle={"col-6 col-xl-3 mb-3 "}
                childStyle={"st"}
                data={data?.primaryOverview}
              />
              <BoxItem
                parentStyle={"col-12 col-lg-6 col-xl-4 mb-3 "}
                childStyle={"st"}
                data={data?.soldAdsOverview}
              />
            </>
          )
        }
      />
      <TradeBox
        parentStyle={"set1"}
        childStyle={"trade"}
        // heading={"Overview"}
        // subHeading={"Adverts Overview"}
        onClick={onsubmit}
        component={
          loading ? (
            <Skeletons1 lines={2} />
          ) : (
            <>
              <BoxItem
                parentStyle={"col-6 col-xl-3 mb-3 "}
                childStyle={"st"}
                data={data?.advertsOverview}
              />
            </>
          )
        }
      />
      <TradeBox
        parentStyle={"set1"}
        childStyle={"trade"}
        // heading={"Overview"}
        subHeading={"Payments Overview"}
        onClick={onsubmit}
        component={
          loading ? (
            <Skeletons1 lines={2} />
          ) : (
            <>
              <BoxItem
                parentStyle={"col-12 col-lg-6 mb-3"}
                childStyle={"st"}
                data={data?.paymentsOverview}
              />
            </>
          )
        }
      />
    </>
  );
};
export default Overview;
