import React, { useEffect } from "react";
import Items from "./items";
import { useState } from "react";
import ScrollMenuComponent from "./ScrollMenuComponent";
import { isImageOrVideo } from "../../../data/utils";
export default function Crousel({ data, setShowCarousel }) {
  // if (data.length === 0) data.push("/assets/images/notFound.png");
  console.log(data)
  const [imageArray, setImageArray] = useState(data);
  useEffect(() => {
    let newArr = data ? [...data] : [];
    newArr = newArr.filter((v) => {
      if (isImageOrVideo(v?.s3URL) === "unknown") {
        return false;
      } else {
        return true;
      }
    });
    setImageArray(newArr);
  }, [data]);

  useEffect(() => {
    if (imageArray?.length == 0) {
      setShowCarousel?.(false);
    }
  }, [imageArray]);
  return (
    <div
      id="carouselSingle"
      // style={{direction: 'ltr'}}
      class="carousel slide w-100"
      // data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <Items imageArray={imageArray} />
      </div>
      {/* <DragScroll data={data} /> */}
      <ScrollMenuComponent
        imageArray={imageArray}
        setImageArray={setImageArray}
        // filterImageArray={filterImageArray}
      />
    </div>
  );
}
