import React from "react";

function ConfirmToggle({ data, closeModal, handleSubmit }) {
  return (
    <div>
      <h5 className="fw-normal">
        Are you sure you want to turn{" "}
        <span className="fw-semibold">{data?.val ? "on" : "off"}</span>{" "}
        "{data?.item?.value}" advert approvals
      </h5>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="button"
          className="themeButtonNo"
          onClick={() => closeModal()}
        >
          No
        </button>
        <button
          type="button"
          className="themeButtonYes"
          onClick={e => {
            handleSubmit(data?.item, data?.val)
            closeModal()
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

export default ConfirmToggle;
