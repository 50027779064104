const BoxImageTitle = ({ parentStyle, childStyle, data }) => {
  return (
    <div className={parentStyle}>
      {data?.length > 0 &&
        data.map((item) => {
          return (
            <div className={childStyle}>
              <h5 className="d-flex gap-1 align-items-center">
                <img src={`assets/images/dash_img/${item.image}`} />
                {item.content}
              </h5>
            </div>
          );
        })}
    </div>
  );
};
export default BoxImageTitle;
