import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BasicDetails from "../../Tools/BasicTable";
import { getAccountDetails } from "../../../Reducer/userAccount/accountDetailsSlice";
import {
  dealerAccountBasicDetailsColumn,
  privateAccountBasicDetailsColumn,
  advertsPrivateAccountColumn,
  advertsDealerAccountColumn,
  transactionPrivateAccountColumn,
  transactionDealerAccountColumn,
  issueOption,
  reasonsToDelist,
} from "../../../data/data";
import AdvertList from "./adverts";
import Transcation from "./transcation";
import { fetchView, fetchDelete } from "../../Functions/DataTable";
import {
  delistAccount,
  getStatusList,
  sendEmail,
  toggleAdDisplay,
  trasncationDeleteFun,
  updateProfile,
} from "../../../services/api";
import BoostrapModel from "../../Tools/BoostrapModel";
import ReasonModel from "../../Tools/ReasonModel";
import swal from "sweetalert";
import { DELIST_MAIL_SUBJECT } from "../../../data/varible";
import Skeletons1 from "../../Tools/Skeletons/Skeletons1";
import KycBox from "../../Tools/KycBox";
import KycModel from "../singlePendingApprovalUser/model";

const AccountDetails = () => {
  const [action, setAction] = React.useState([]);
  const [actionTrans, setActionTrans] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show1, setShow1] = React.useState(false);
  const account = useSelector((state) => state.accountDetails);
  const userType = useSelector((state) => state.accountDetails.userType);
  const submitting = useSelector((state) => state.accountDetails.submitting);
  const [loading, setLoading] = useState(false);
  const [loadingWithoutMail, setLoadingWithoutMail] = useState(false);
  const [statusList, setStatusList] = useState();
  const [recallAds, setRecallAds] = useState(1)
  const [notes, setNotes] = useState(
    account?.details?.details?.adminNotes?.notes
  );
  console.log("userTypeeeee", userType);
  const [show, setShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [state, setState] = React.useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });

  useEffect(() => {
    setNotes(account?.details?.details?.adminNotes?.notes);
  }, [account]);
  let basicColumns =
    account?.userType === "dealer"
      ? dealerAccountBasicDetailsColumn
      : privateAccountBasicDetailsColumn;
  let transactionsColumns =
    account?.userType === "dealer"
      ? transactionDealerAccountColumn
      : transactionPrivateAccountColumn;
  let advertColumns =
    account?.userType === "dealer"
      ? advertsDealerAccountColumn
      : advertsPrivateAccountColumn;

  const [params] = useSearchParams();
  const userId = params.get("id");
  const body = {
    id: userId,
  };
  const getData = () => {
    dispatch(getAccountDetails(body));
    getStatusList().then((res) => {
      setStatusList(res?.data);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    getData();
    fetchActionsTrans();
  }, [dispatch, userId]);

  const handleSubmit = (data) => {
    setLoading(true);
    let formData = {};
    switch (state?.modalType) {
      case "addNote":
        console.log("addnote", data);
        const updateNotes = {
          userId: state?.id,
          adminNotes: { notes: state?.notes },
        };
        updateProfile(updateNotes).then((res) => {
          if (res?.status) {
            getData();
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "warning":
        formData = {
          subject: data?.title,
          message: data?.body,
          userIds: [userId],
          type: "0",
          attachments: selectedFile.map((v) => v?.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res.status) {
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "delist":
        const updateData = {
          userId: state?.id,
          allowedToLogin: !state?.allowedToLogin,
          isAdImageUploaded: "YES",
        };
        delistAccount({ id: state?.id }).then((res) => {
          if (res?.status) {
            getData()
            setRecallAds(recallAds => recallAds + 1)
            navigate('/user_accounts')
            formData = {
              subject: state?.allowedToLogin
                ? DELIST_MAIL_SUBJECT + data?.reason
                : data?.title,
              message: data?.body,
              userIds: [userId],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData);
            swal("Success", res?.message, "success");
            setShow(false);

          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        // updateProfile(updateData).then((res) => {
        //   if (res?.status) {
        //     formData = {
        //       subject: state?.allowedToLogin
        //         ? DELIST_MAIL_SUBJECT + data?.reason
        //         : data?.title,
        //       message: data?.body,
        //       userIds: [userId],
        //       type: "0",
        //       attachments: selectedFile.map((v) => v?.s3URL),
        //     };
        //     sendEmail(formData);
        //     swal("Success", res?.message, "success");
        //     setShow(false);
        //   } else {
        //     swal("Error", res?.message, "error");
        //   }
        //   setLoading(false);
        // });
        break;
    }
  };

  const delistRelistWithoutMail = () => {
    setLoadingWithoutMail(true);
    const updateData = {
      userId: state?.id,
      allowedToLogin: !state?.allowedToLogin,
      isAdImageUploaded: "YES",
    };
    delistAccount({ id: state?.id }).then((res) => {
      if (res?.status) {
        getData();
        setRecallAds(recallAds => recallAds + 1)
        navigate('/user_accounts')
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoadingWithoutMail(false);
    });
    // updateProfile(updateData).then((res) => {
    // if (res?.status) {
    //   getData();
    //   swal("Success", res?.message, "success");
    //   setShow(false);
    // } else {
    //   swal("Error", res?.message, "error");
    // }
    // setLoadingWithoutMail(false);
    // });
  };

  const fetchActionsTrans = async () => {
    const edit = fetchView(
      "Edit",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const viewNoraml = fetchView(
      "View",
      getAccountDetails,
      "account_details",
      dispatch,
      navigate,
      "",
      "view"
    );
    const deleted = fetchDelete(
      "Delete",
      trasncationDeleteFun,
      dispatch,
      "",
      getAccountDetails
    );
    setActionTrans([viewNoraml, edit, deleted]);
  };

  const basicRows = {
    ...account?.details?.details,
    about: account?.details?.details?.additionalInfo?.about,
    gender: account?.details?.details?.gender?.[0]?.name?.en,
  };
  return (
    <div class="overview account_details">
      <h6 class="back d-flex justify-content-between mb-4">
        <a onClick={() => navigate("/user_accounts")}>
          <img src="../assets/images/backbt.png" /> {"Go back"}
        </a>
      </h6>
      <div className="fs-3 mb-4 fw-bold">{"Account details"}</div>
      {account?.details?.details?.allowedToLogin && (
        <div className="d-flex justify-content-end align-items-center gap-2">
          <div className="col-3 col-lg-2 mb-2">
            <button
              type="button"
              className={` w-100 ms-0 themeButtonNo`}
              onClick={() => {
                const type = account?.details?.details?.allowedToLogin;
                setState({
                  id: userId,
                  heading: type ? "Delist account ?" : "Relist account ?",
                  title: type ? "delisting account" : "relisting account",
                  buttonTitle: type ? "Delist" : "Relist",
                  option: reasonsToDelist,
                  type: "selection",
                  modalType: "delist",
                  allowedToLogin: type,
                });
                setShow(!show);
              }}
            >
              {"Delist"}
            </button>
          </div>
          <div className="col-4 col-lg-2 mb-2">
            <button
              type="button"
              className=" w-100 ms-0 themeButtonYes"
              onClick={() => {
                setState({
                  id: userId,
                  title: "Issue Warning",
                  heading: "Issue Warning ?",
                  buttonTitle: "Submit",
                  option: issueOption,
                  type: "selection",
                  modalType: "warning",
                });
                setShow(!show);
              }}
            >
              Issue warning
            </button>
          </div>
        </div>
      )}
      <div class="tab-content mt-4" id="myTabContent">
        {submitting ? (
          <>
            <Skeletons1 lines={2} />
          </>
        ) : (
          <BasicDetails
            heading={"Basic Details"}
            column={basicColumns || []}
            row={basicRows || []}
            parentStyle={"set_list"}
            childStyle={"col-6 col-lg-4 col-xl-4 mb-4"}
          />
        )}
        {!submitting && (
          <div className="set_list">
            <div
              className={`d-flex justify-content-${
                account?.details?.details?.adminNotes?.notes?.length > 0
                  ? "between"
                  : "between"
              }`}
            >
              <h3 style={{ marginBottom: "0px" }}>Admin notes</h3>
              {/* {account?.details?.details?.adminNotes?.notes?.length > 0 && (
              )} */}
              <span className="me-2">
                <button
                  type="button"
                  className="themeButtonYes  w-100"
                  onClick={() => {
                    setState({
                      id: userId,
                      title: "Add note",
                      heading: "Add note",
                      buttonTitle: "Save",
                      option: [],
                      type: "note",
                      modalType: "addNote",
                      notes: account?.details?.details?.adminNotes?.notes || [],
                    });
                    setShow(!show);
                  }}
                >
                  {account?.details?.details?.adminNotes?.notes?.length > 0
                    ? "Edit notes"
                    : "Add notes"}
                </button>
              </span>
            </div>
            <ul style={{ listStyleType: "none", padding: "20px 0px 0px 0px" }}>
              {notes?.map((v, i) => {
                return (
                  <li
                    className="d-flex justify-content-between p-2 rounded border mb-1"
                    key={i}
                  >
                    <span>{v}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {account?.userType === "dealer" && (
          <div class={"w-100"}>
            <div
              class={
                "Documents d-block d-lg-flex justify-content-between align-items-center p-4"
              }
            >
              <h6
                style={{ fontWeight: 700, fontSize: "24px", color: "#2B2B2B" }}
              >
                <span>KYC documents</span> <br />{" "}
                <span className="fw-semibold fs-5">
                  {statusList &&
                    statusList.filter(
                      (v) => v?._id === account?.details?.details?.kyc
                    )?.[0]?.name?.en}
                </span>
              </h6>
              {account?.details?.details?.additionalInfo?.dealerKycDocuments
                ?.length > 0 && (
                <p role="button" onClick={() => setShow1(true)}>
                  Documents
                  <a>
                    <img src={"../assets/images/eye2.png"} alt="eye" />
                  </a>
                </p>
              )}
            </div>
          </div>
        )}
        <AdvertList
          columns={advertColumns}
          // rows={activeAdvertRows || []}
          action={action}
          userId={userId}
          state={state}
          setState={setState}
          setShow={setShow}
          recallAds={recallAds}
        />
        <Transcation
          columns={transactionsColumns}
          action={actionTrans}
          userId={userId}
        />
        <BoostrapModel
          show={show}
          heading={state.heading}
          onHide={() => setShow(!show)}
          style={"w-100"}
          component={
            <ReasonModel
              id={state.id}
              label={state.title}
              buttonTitle={state.buttonTitle}
              option={state.option}
              type={state.type}
              data={state}
              setData={setState}
              handleSubmitEvent={handleSubmit}
              onHide={() => setShow(!show)}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              loading={loading}
              loadingWithoutMail={loadingWithoutMail}
              continueWithoutCallback={delistRelistWithoutMail}
            />
          }
        />
        <BoostrapModel
          show={show1}
          heading={`Kyc for ${account?.details?.details?.dealerShipName}`}
          style={"warning warningKyc"}
          component={<KycModel row={account?.details?.details} />}
          onHide={() => setShow1(false)}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
