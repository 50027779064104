import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@iconify/react/dist/iconify.js";
import { isImageOrVideo } from "../../../data/utils";

function Thumbnails({ item, removeIndex, index, pos, dragging, obj }) {
  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({ id: index });
  const resolve = () => {
    const type = isImageOrVideo(item);
    switch (type) {
      case "video":
        return (
          <div
            class="st "
            style={{
              minWidth: "84px",
              width: "100%",
            }}
          >
            {dragging?.loading && dragging?.id == index ? (
              <>
                <Icon
                  icon="lets-icons:move"
                  className="bg-light p-1 rounded-circle"
                  fontSize={30}
                  style={{ color: "#5b5b5b" }}
                />
              </>
            ) : (
              <>
                <img
                  class="img-cros"
                  id="blah"
                  onClick={() => {
                    removeIndex(pos, obj);
                  }}
                  src={"/assets/images/close_img.png"}
                  alt="your image"
                  style={{ zIndex: 99999, cursor: "pointer" }}
                />
              </>
            )}
            <video className="h-100 w-100" style={{ objectFit: "cover" }}>
              <source src={item} />
            </video>
          </div>
        );
      default:
        return (
          <div
            class="st"
            style={{
              backgroundImage: `url(${item})`,
              minWidth: "84px",
              width: "100%",
              backgroundSize: "cover",
            }}
          >
            {dragging?.loading && dragging?.id == index ? (
              <>
                <Icon
                  icon="lets-icons:move"
                  className="bg-light p-1 rounded-circle"
                  fontSize={30}
                  style={{ color: "#5b5b5b" }}
                />
              </>
            ) : (
              <>
                <img
                  class="img-cros"
                  id="blah"
                  onClick={() => {
                    removeIndex(pos, obj);
                  }}
                  src={"/assets/images/close_img.png"}
                  alt="your image"
                  style={{ zIndex: 99999, cursor: "pointer" }}
                />
              </>
            )}
          </div>
        );
    }
  };
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
    // pointerEvents: 'none',
    // boxShadow: dragging?.loading && dragging?.id == index ? "10px 10px 10px 10px red" : "",
    // borderRadius: "7px",
  };
  return (
    <div
      ref={setNodeRef}
      className="draggable"
      {...attributes}
      {...listeners}
      style={style}
    >
      {resolve()}
      {/* {item.id} */}
    </div>
  );
}

export default Thumbnails;
