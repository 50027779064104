import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import BasicTable from "../../Tools/Table";
import { userAccountList } from "../../../Reducer/userAccount/userAccountSlice";
import {
  dealerAccountColumns,
  reasonsToDelist,
  userAccountColumns,
} from "../../../data/data";
import ReasonModel from "../../Tools/ReasonModel";
import BoostrapModel from "../../Tools/BoostrapModel";
import { issueOption } from "../../../data/data";
import { delistAccount, sendEmail, updateProfile } from "../../../services/api";
import { DELIST_MAIL_SUBJECT, ITEM_PER_PAGE } from "../../../data/varible";
import { useNavigate } from "react-router-dom";
import { Input, InputGroup } from "reactstrap";
import {
  useDidMountEffect,
  useDidMountEffectWithDebounce,
} from "../../../data/hooks";
import LazyLoad from "react-lazy-load";
import SingleBoxItem from "../../Tools/SingleBoxItem";

const UserAccount = () => {
  const localStorageKeys = {
    currPage: "currPageAllAccounts",
    tab: "tabAllAccounts",
    link: "linksAllAccounts",
  };
  const tabs = [
    {
      value: "brand",
      label: "Brands",
    },
    {
      value: "dealer",
      label: "Dealers",
    },
    {
      value: "user",
      label: "Private users",
    },
  ];
  const links = [
    {
      value: "live_account",
      label: "Live accounts",
      showFor: ["", "dealer", "user", "brand"],
    },
    {
      value: "pending_account",
      label: "Pending accounts",
      showFor: ["", "dealer", "brand"],
    },
    {
      value: "rejected_account",
      label: "Rejected accounts",
      showFor: ["", "dealer", "brand"],
    },
    {
      value: "delisted_account",
      label: "Deslisted accounts",
      showFor: ["", "dealer", "user", "brand"],
    },
  ];

  const navigate = useNavigate();
  const rows = useSelector((state) => state.userAccountList?.userAccountList);
  const submitting = useSelector((state) => state.userAccountList?.submitting);
  const [loading, setLoading] = useState(false);
  const [loadingWithoutMail, setLoadingWithoutMail] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = React.useState(
    localStorage.getItem(localStorageKeys.tab) || ""
  );
  const [selectedLink, setSelectedLink] = useState(
    links.filter(
      (v) => v?.value === localStorage.getItem(localStorageKeys.link)
    )?.[0] || {}
  );
  const [show, setShow] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [state, setState] = React.useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });
  console.log(localStorage.getItem(localStorageKeys.link));
  // Pagination state
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem(localStorageKeys.currPage)) || 1
  );
  const limit = ITEM_PER_PAGE;
  const getData = () => {
    let accountType = "";
    if (selectedLink?.showFor?.includes(active)) {
      accountType = selectedLink?.value;
    }
    dispatch(
      userAccountList({
        page: currentPage,
        limit: limit,
        userType: active,
        accountType: accountType,
      })
    );
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, [dispatch, currentPage, active, selectedLink]);

  const data =
    rows?.users &&
    rows?.users?.map((item) => {
      return {
        ...item,
        profilePicture: (
          <div className="d-flex justify-content-start h-100">
            <LazyLoad height={40}>
              <img
                style={{ objectFit: "cover" }}
                height={40}
                width={40}
                className="rounded-circle"
                src={item?.profilePicture || "assets/images/ap2.png"}
              />
            </LazyLoad>
          </div>
        ),
      };
    });
  const handleSubmit = async (data) => {
    if (loading) return;

    setLoading(true);
    let formData = {};
    switch (state?.modalType) {
      case "warning":
        formData = {
          subject: data?.title,
          message: data?.body,
          userIds: [state?.id],
          type: "0",
          attachments: selectedFile.map((v) => v?.s3URL),
        };
        sendEmail(formData).then((res) => {
          if (res.status) {
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "delist":
        const updateData = {
          userId: state?.id,
          allowedToLogin: !state?.allowedToLogin,
        };
        // updateProfile(updateData)
        delistAccount({ id: state?.id }).then((res) => {
          if (res?.status) {
            formData = {
              subject: state?.allowedToLogin
                ? DELIST_MAIL_SUBJECT + data?.reason
                : data?.title,
              message: data?.body,
              userIds: [state?.id],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData);
            getData();
            swal("Success", res?.message, "success");
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
      case "addNote":
        console.log("addnote", data);
        const updateNotes = {
          userId: state?.id,
          adminNotes: { notes: state?.notes },
        };
        updateProfile(updateNotes).then((res) => {
          if (res?.status) {
            getData();
            setShow(false);
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
    }
  };

  const delistRelistWithoutMail = () => {
    setLoadingWithoutMail(true);
    const updateData = {
      userId: state?.id,
      // allowedToLogin: !state?.allowedToLogin,
      allowedToLogin: false,
    };
    // updateProfile(updateData)
    delistAccount({ id: state?.id }).then((res) => {
      if (res?.status) {
        getData();
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoadingWithoutMail(false);
    });
  };

  const actions = [
    {
      label: "Issue Warning ?",
      optional: true,
      optionalString: "allowedToLogin",
      onclick: (id) => {
        setState({
          id: id,
          title: "Issue Warning",
          heading: "Issue Warning ?",
          buttonTitle: "Submit",
          option: issueOption,
          type: "selection",
          modalType: "warning",
        });
        setShow(!show);
      },
    },
    {
      label: "Delist account ?",
      optional: true,
      optionalString: "allowedToLogin",
      onclick: (id, row) => {
        setState({
          id: id,
          heading: "Delist account ?",
          subHeading:
            "Delisting this account will also delist all ads posted by it.",
          title: "delisting account",
          buttonTitle: "Delist",
          option: reasonsToDelist,
          type: "selection",
          modalType: "delist",
          allowedToLogin: false,
        });
        setShow(!show);
      },
    },
    // {
    //   label: "Refund for Transcation",
    //   onclick: (id) => {
    //     setState({//       id: id,
    //       title: "Refund for Transcation",
    //       heading: "Refund for Transcation",
    //       buttonTitle: "Refund for Transcation",
    //       option: refundOption,
    //       type: "selection",
    //     });
    //     setShow(!show);
    //   },
    // },
    {
      label: "Add note",
      onclick: (id, data) => {
        setState({
          id: id,
          title: "Add note",
          heading: "Add note",
          buttonTitle: "Save",
          option: [],
          type: "note",
          modalType: "addNote",
          notes: data?.adminNotes?.notes || [],
        });
        setShow(!show);
      },
    },
  ];

  useDidMountEffectWithDebounce(
    () => {
      dispatch(
        userAccountList({
          page: currentPage,
          limit: limit,
          userType: active,
          name: searchTerm,
        })
      );
    },
    [searchTerm],
    500
  );

  useDidMountEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      localStorage.setItem(localStorageKeys.currPage, 1);
    }
  }, [active, selectedLink, searchTerm]);

  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">{"All accounts"}</div>
      <div className="row d-flex justify-content-between">
        <div className="col-sm-12 col-lg-6 mb-3">
          <div
            class="btn-group shadow-sm my-tabs"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            {tabs.map((item) => {
              return (
                <button
                  disabled={submitting}
                  type="button"
                  class={`btn btn-light ${
                    active == item?.value ? "activeButton" : ""
                  }`}
                  // style={{ width: "150px" }}
                  onClick={async () => {
                    if (active === item?.value) {
                      localStorage.setItem(localStorageKeys.tab, "");
                      setActive("");
                    } else {
                      localStorage.setItem(localStorageKeys.tab, item?.value);
                      setActive(item?.value);
                    }
                  }}
                >
                  {item?.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-sm-12 col-lg-4 ">
          <InputGroup className="position-relative" style={{ height: "41px" }}>
            <Input
              className="border-0 shadow-sm"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </div>
      </div>
      <div className="d-flex justify-content-start flex-wrap gap-1 mt-3">
        {links.map((item) => {
          if (item?.showFor?.includes(active)) {
            return (
              <div
                disabled={submitting}
                type="button"
                class={` shadow-sm rounded-1 px-2 ${
                  selectedLink?.value == item?.value ? "activeButton" : ""
                }`}
                // style={{ width: "150px" }}
                onClick={async () => {
                  if (!submitting) {
                    if (selectedLink?.value === item?.value) {
                      localStorage.setItem(localStorageKeys.link, "");
                      setSelectedLink("");
                    } else {
                      localStorage.setItem(localStorageKeys.link, item?.value);
                      setSelectedLink(item);
                    }
                  }
                }}
              >
                {item?.label}
              </div>
            );
          }
        })}
      </div>
      <br />
      <BasicTable
        rows={data || []}
        columns={active === "user" ? userAccountColumns : dealerAccountColumns}
        actionType={"dropdown"}
        actions={actions}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={rows?.totalPages}
        submitting={submitting}
        onRowClick={(e) => {
          navigate(`/account_details?id=${e?._id}`);
        }}
        localCurrPageKey={localStorageKeys.currPage}
      />
      <SingleBoxItem
        parentStyle={"indiv"}
        childStyle={"st"}
        heading={rows?.totalAds}
        description={"Total Results"}
      />
      <BoostrapModel
        show={show}
        heading={state.heading}
        subHeading={state.subHeading}
        onHide={() => setShow(!show)}
        style={"w-100"}
        component={
          <ReasonModel
            id={state.id}
            label={state.title}
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            data={state}
            setData={setState}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
            loadingWithoutMail={loadingWithoutMail}
            continueWithoutCallback={delistRelistWithoutMail}
          />
        }
      />
    </div>
  );
};
export default UserAccount;
