import React, { useEffect } from "react";
import Options from "./options";
const SelectOptions = ({
  noAll,
  style,
  selectedOption,
  name,
  values,
  onChange,
  placeholder,
  boxStyle,
  disabled,
  namePrefix,
  nameSuffix,
  isPrice,
  loading,
}) => {
  const lang = "en";
  const skip = ["", "", null, undefined, NaN];

  return (
    <select
      className={style}
      name={name}
      onChange={onChange}
      style={boxStyle}
      disabled={disabled || loading}
      data-theme="none"
      data-role="none"
    >
      <Options
        value={placeholder}
        name={placeholder || "Select"}
        hidden={true}
        disabled={true}
        selected={selectedOption?.length == 0 || skip.includes(selectedOption)}
      ></Options>
      {!noAll && (
        <Options
          value={name === "cities" ? "National" : "Any"}
          name={name === "cities" ? "National" : "Any"}
          selected={["Any", "National"].includes(selectedOption)}
        ></Options>
      )}
      {values?.map((item, key) => {
        let value =
          item?.name?.["English"] ||
          item?.name?.[lang] ||
          item.name ||
          item.label ||
          item;
        // let nameValue = t(value)
        // if(namePrefix) {
        //   nameValue = namePrefix.concat(nameValue)
        // }
        // if(nameSuffix) {
        //   nameValue = nameValue.concat(nameSuffix)
        // }

        return (
          <Options
            value={item?.value == 0 ? 0 : item?.value || value}
            name={value}
            selected={[value, item?.value].includes(selectedOption)}
            // disabled={item.disabled && item.disabled ? true : null}
            style={""}
            key={value}
          />
        );
      })}
    </select>
  );
};
export default SelectOptions;
