import React from "react";
import { Input } from "reactstrap";

const SearchBar = ({ event, placeholder, value, onChange }) => {
  return (
    <>
      {/* <form> */}
      <p>
        <Input placeholder="Search" value={value} onChange={onChange} style={{ outline: "none" }} />
      </p>
      {/* <input
        class="me-2"
        style={{ outline: "none" }}
        type="search"
        placeholder={`${placeholder ? placeholder : "Search"}`}
        // aria-label="Search"
        // onChange={(e) => setData(e.target.value)}
        value={value}
        onChange={onChange}
      /> */}
      {/* <button
        class="btn btn-outline-success bg-light"
        onClick={(e) => {
          e.preventDefault();
          event.onclick(data);
          }}
          >
          <i class="fa-solid fa-magnifying-glass"></i>
          </button> */}
      {/* </form> */}
    </>
  );
};
export default SearchBar;
