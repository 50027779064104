import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TabNavItem from "../../Tools/TabNavItem";
import { pricingFun } from "../../../Reducer/pricing/pricingSlice";
import Subscription from "./subscription";
import BoostrapModel from "../../Tools/BoostrapModel";
import EditForm from "./editForm";
import AddForm from "./addForm";
import {
  getGlobalSettings,
  setGlobalSettings,
  updateSubscriptionPlan,
} from "../../../services/api";
import swal from "sweetalert";
import PriceSkeletons from "../../Tools/Skeletons/PriceSkeletons";
import { Input } from "reactstrap";
import Button from "../../Tools/Button";
import { current } from "@reduxjs/toolkit";
import { Icon } from "@iconify/react/dist/iconify.js";

const Pricing = () => {
  const [show, setShow] = React.useState(false);
  const [addShow, setAddShow] = React.useState(false);
  const data = useSelector((state) => state.pricing?.data);
  const submitting = useSelector((state) => state.pricing?.submitting);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = React.useState("brand");
  const [exchange, setExchange] = useState({
    initial: "",
    current: "",
    loading: false,
  });
  // const [data, setData] = React.useState([]);
  const [modalData, setModalData] = React.useState({});
  const dispatch = useDispatch();
  const [sellRent, setSellRent] = useState("Sell");
  const getData = () => {
    dispatch(
      pricingFun({
        adType: active === "checkMyCar" ? "Rent" : sellRent,
        type: active,
      })
    );
  };
  useEffect(() => {
    getData();
  }, [dispatch, sellRent, active]);

  const getGlobalSetting = async () => {
    setExchange({ ...exchange, loading: true, initial: "", current: "" });
    await getGlobalSettings().then((res) => {
      if (res?.status) {
        const val = res?.data?.exchangeRateDollarToIqd || 0;
        setExchange({
          ...exchange,
          initial: val,
          current: val,
          loading: false,
        });
      } else {
        setExchange({ ...exchange, loading: false });
      }
    });
  };

  useEffect(() => {
    getGlobalSetting();
  }, []);

  const handleSaveRate = async () => {
    setExchange({ ...exchange, loading: true });
    await setGlobalSettings({ exchangeRateDollarToIqd: exchange.current }).then(
      (res) => {
        if (res?.status) {
          swal("Success", "Exchange rate updated", "success");
          getGlobalSetting();
        }
      }
    );
    // setExchange({ ...exchange, loading: false });
  };

  const onClick = (data) => {
    setModalData(data);
    setShow(true);
  };
  const handleSubmit = (data1) => {
    const formData = {
      price: data1?.price,
    };
    setLoading(true);
    updateSubscriptionPlan(formData, modalData?._id).then((res) => {
      if (res?.status) {
        getData();
        swal("Success", res?.message, "success");
        setShow(false);
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };

  console.log(exchange);

  return (
    <div class="dashnboard pricing">
      <div className="fs-3 mb-4 fw-bold">{"Pricing Controls"}</div>
      <div className="row d-flex justify-content-between">
        <div className="col-lg-6">
          <h5>Exchange Rate</h5>
        </div>
        <div className="col-lg-4 d-flex justify-content-end mx-0  position-relative">
          {exchange?.current !== exchange.initial && (
            <>
              <button
                onClick={(e) => handleSaveRate()}
                type="button"
                disabled={!exchange?.current}
                style={{
                  fontSize: "13px",
                  position: "absolute",
                  margin: "2px",
                  height: "90%",
                  width: "70px",
                }}
                className="btn btn-light activeButton d-flex align-items-center justify-content-center"
              >
                {exchange.loading ? (
                  <Icon
                    fontSize={20}
                    icon="eos-icons:bubble-loading"
                    style={{ color: "#fff" }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </>
          )}
          <Input
            type="number"
            disabled={exchange.loading}
            value={exchange.current}
            style={{ boxShadow: "none" }}
            className=" shadow-sm  border-0"
            onChange={(e) =>
              setExchange({
                ...exchange,
                current: parseFloat(e.target.value),
              })
            }
          />
        </div>
      </div>
      <h3 className="d-flex justify-content-end flex-wrap mt-4">
        <div
          class="btn-group shadow-sm my-tabs"
          style={{ borderRadius: "4px", height: "43px" }}
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class={`text-nowrap btn btn-light ${
              active == "brand" ? "activeButton" : ""
            }`}
            // style={{ width: "150px" }}
            onClick={() => {
              setActive("brand");
              // setData(indiviualUsers);
            }}
          >
            Brand
          </button>
          <button
            type="button"
            class={`text-nowrap btn btn-light ${
              active == "dealer" ? "activeButton" : ""
            }`}
            // style={{ width: "150px" }}
            onClick={() => {
              setActive("dealer");
              // setData(tradeUsers);
            }}
          >
            Dealer
          </button>
          <button
            type="button"
            class={`text-nowrap btn btn-light ${
              active == "individual" ? "activeButton" : ""
            }`}
            // style={{ width: "150px" }}
            onClick={() => {
              setActive("individual");
              // setData(indiviualUsers);
            }}
          >
            Private users
          </button>

          <button
            type="button"
            class={`text-nowrap btn btn-light ${
              active == "checkMyCar" ? "activeButton" : ""
            }`}
            // style={{ width: "150px" }}
            onClick={() => {
              setActive("checkMyCar");
              // setData(indiviualUsers);
            }}
          >
            Check my car
          </button>
        </div>

        {active !== "checkMyCar" && (
          <div
            class="btn-group shadow-sm mt-3 w-50"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class={`btn btn-light ${
                sellRent == "Sell" ? "activeButton" : ""
              }`}
              // style={{ width: "121px" }}
              onClick={() => {
                setSellRent("Sell");
              }}
            >
              Sell
            </button>
            <button
              type="button"
              class={`btn btn-light ${
                sellRent == "Rent" ? "activeButton" : ""
              }`}
              // style={{ width: "121px" }}
              onClick={() => {
                setSellRent("Rent");
              }}
            >
              Rent
            </button>
          </div>
        )}
      </h3>
      {submitting ? (
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <PriceSkeletons />
          </div>
        </div>
      ) : (
        <Subscription data={data || []} type={active} onClick={onClick} />
      )}
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={show}
        component={
          <EditForm
            rawData={modalData}
            onHide={() => setShow(false)}
            handleSubmitForm={handleSubmit}
            loading={loading}
          />
        }
        onHide={() => setShow(!show)}
        heading={`Edit plan - ${modalData?.name}`}
      />
      <BoostrapModel
        style={"Edit_section"}
        size={"lg"}
        show={addShow}
        component={<AddForm onHide={() => setAddShow(!addShow)} />}
        onHide={() => setAddShow(!addShow)}
        heading={"Edit plan"}
      />
    </div>
  );
};
export default Pricing;
