import React, { useState } from "react";
//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeFromStorage, uploadToStorage } from "../../../services/api";
import { Input } from "reactstrap";
import { Icon } from "@iconify/react/dist/iconify.js";
import { isImageOrVideo } from "../../../data/utils";

export default function TextareaWithAttatch({
  rtl,
  selectedFile,
  setSelectedFile,
  control,
  errors,
  name,
  textareaPlaceholder,
  showAttatch,
  textareaHeight,
  attatchStyles,
}) {
  const [uploading, setUploading] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    if (file) {
      setUploading(true);
      uploadToStorage(formData, 2).then((res) => {
        if (res?.status) {
          setSelectedFile([...selectedFile, res?.data?.[0]]);
          setUploading(false);
        }
      });
    }
  };

  const removeFile = (item) => {
    console.log(item);
    removeFromStorage(item?._id).then((res) => {
      if (res?.status) {
        setSelectedFile(selectedFile.filter((v) => v._id !== item?._id));
      }
    });
  };

  return (
    <div>
      {" "}
      <div style={{ position: "relative" }}>
        {showAttatch && (
          <>
            <label for="fileInput" style={attatchStyles}>
              <span>
                {uploading ? (
                  <>
                    <Icon
                      icon="eos-icons:bubble-loading"
                      fontSize={20}
                      style={{ color: "#2b2b2b" }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/assets/images/paper-clip.png"
                      alt="img"
                      style={{ width: "20px" }}
                    />
                  </>
                )}
              </span>
            </label>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onClick={(e) => (e.target.value = null)}
              onChange={handleFileChange}
            />
          </>
        )}
        <Controller
          control={control}
          name={"body"}
          render={({ field }) => (
            <Input
              style={{
                height: textareaHeight,
                resize: "none",
                direction: rtl ? "rtl" : "ltr",
              }}
              type="textarea"
              className="d-block"
              value={field.value}
              onChange={field.onChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent the newline
                }
              }}
              onBlur={field.onBlur}
              placeholder={textareaPlaceholder}
            />
          )}
        />
        {errors.body && <p className="helperText">{errors.body.message}</p>}
      </div>
      {showAttatch && (
        <ul
          className="d-flex flex-wrap mt-2"
          style={{ listStyleType: "none", padding: "0px 0px 0px 0px" }}
        >
          {selectedFile.map((item, i) => {
            const fileType = isImageOrVideo(item?.s3URL);
            return (
              <li
                className="col-lg-4 p-1"
                key={i}
                style={{ padding: "0px 15px 0px", position: "relative" }}
              >
                <Icon
                  className="rounded-circle bg-light"
                  icon="ic:round-delete"
                  fontSize={18}
                  onClick={() => removeFile(item)}
                  style={{
                    color: "#e84a4a",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                />
                {fileType === "image" && (
                  <img
                    src={item?.s3URL}
                    className="w-100 rounded"
                    style={{ objectFit: "cover", height: "100px" }}
                    alt="img"
                  />
                )}
                {fileType === "video" && (
                  <video
                    controls
                    className="w-100 rounded"
                    style={{ objectFit: "cover", height: "100px" }}
                  >
                    <source src={item?.s3URL} />
                  </video>
                )}
                {fileType === "unknown" && (
                  <img
                    src={"/assets/images/docsingle.png"}
                    className="w-100 rounded"
                    style={{ objectFit: "cover", height: "100px" }}
                    alt="img"
                  />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
