import React, { useEffect, useState } from "react";
import LiveTrafffic from "./liveTraffic";
import Metrics from "./metrics";
import KeyStats from "./keyStats";
import Overview from "./overview";
import { getDashboardData } from "../../../services/api";
import { formatDashboardData } from "./dataFormattingUtil";
import { toCamelCase } from "../../../data/utils";
// document.title = "dashboard";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [dashboardData, setData] = useState(JSON.parse(localStorage.getItem('dashboard-data')) || {});
  useEffect(() => {
    const localData = localStorage.getItem('dashboard-data')
    if(localData) {
      setData(JSON.parse(localData))
    } else {
      setLoading(true);
    }
    setSyncing(true)
    getDashboardData().then((res) => {
      if (res.status) {
        const formattedData = formatDashboardData(res?.data);
        localStorage.setItem('dashboard-data', JSON.stringify(formattedData))
        setData(formattedData);
      }
      setLoading(false);
      setSyncing(false)
    });
  }, []);
  console.log(dashboardData)
  return (
    <>
      <Overview data={dashboardData?.overview} loading={loading} syncing={syncing}/>
      <KeyStats data={dashboardData?.keyStats} loading={loading} />
      {/* <Metrics data={dashboardData?.metrics} loading={loading} /> */}
      {/* <LiveTrafffic /> */}
    </>
  );
};

export default DashboardPage;
